
import './App.css';
import React from 'react';
import LandingPage from './components/LandingPage';
import EnvelopeMath from './components/EnvelopeMath';
import EnvelopeMathPrivacy from './components/EnvelopeMathPrivacy';


import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

function App() {
  return (
    <div className="App">
        <Router>
          <Routes>
              <Route path="/" element={<LandingPage />} /> 
              <Route path="/envelope-math" element={<EnvelopeMath />} /> 
              <Route path="/envelope-math-privacy-policy" element={<EnvelopeMathPrivacy />} /> 
          </Routes>
        </Router>
    </div>
  );
}

export default App;
