import React from 'react';

function LandingPage() {


  return (
    <div className="landing-page container">
      <div className="h5 mt-5 mb-2">{/*I like to build things*/}My Projects</div> 
      <br />

      <a href="https://bitprops.io">bitprops.io</a> - Simplified real estate investing <br />
      <a href="https://clutchpotato.com">clutchpotato.com</a> - Custom cover letters to make job applications shine <br />
      <a href="https://datadelve.netlify.app">datadelve</a> - Ask your data anything <br />
      <a href="/envelope-math">envelope math</a> - Practice number sense and estimation skills <br />
      <a href="https://placelift.io">placelift.io</a> - Home renovation management <br />
      <a href="https://sourcelift.netlify.app">sourcelift</a> - Access structured data from any source <br />
      <a href="https://threedaily.co">threedaily.co</a> - Convert long-term health goals into three daily tasks <br />
      

    </div>
    
  );
}

export default LandingPage;
