import React from 'react';

function EnvelopeMath() {


  return (
    <div className="landing-page container">
      

      <div className="h4 mt-5">    
        <span className="pr-5">Envelope Math</span> 
      </div>
      <div className="h5 mt-3">    
        <a href="https://apps.apple.com/us/app/envelope-math/id6502670559?itscg=30200&amp;itsct=apps_box_appicon" style={{ width: '50px', height: '50px', borderRadius: '22%', overflow: 'hidden', display: 'inline-block', verticalAlign: 'middle'}}><img src="https://is1-ssl.mzstatic.com/image/thumb/Purple221/v4/eb/8f/d6/eb8fd63f-a23a-0ed7-f58f-13f5ffde575c/AppIcon-0-0-1x_U007epad-0-P3-85-220.png/540x540bb.jpg" alt="Envelope Math" style={{ width: '50px', height: '50px', borderRadius: '22%', overflow: 'hidden', display: 'inline-block', verticalAlign: 'middle'}}/></a>
      </div>

      <div className="mt-4">
      <p className="h5">Practice number sense and estimation skills</p>

      <p>      
        We are taught that arithmetic should be precise. 
        But precision is rarely a make-or-break for most decisions. 
        Back-of-envelope math, or napkin math, is a tried and true method to quickly assess value. 
        Having a good-enough estimate, and being able to assess values in real time helps in countless 
        situations, from quickly summing a check, to determining the total addressable market for a new 
        product line. Envelope Math can help you practice your estimation skills and number sense with 
        numbers both large and small, so you're ready to evaluate the nitty gritty whenever you 
        need to.
      </p>

      <a href="https://apps.apple.com/us/app/envelope-math/id6502670559?itsct=apps_box_badge&amp;itscg=30200" style={{ display: 'inline-block', overflow: 'hidden', borderRadius: '13px', width: '200px', height: '68px' }}><img src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1716249600" alt="Download on the App Store" style={{ display: 'inline-block', overflow: 'hidden', borderRadius: '13px', width: '200px', height: '68px' }}/></a>
      </div>

    </div>
    
  );
}

export default EnvelopeMath;
